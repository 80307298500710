.Mobile {
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  width: 100vw;
  max-width: 728px;
  margin: 0 auto;
  
  iframe {
    display: block;
  }
  div {
    max-width: 100%;
  }
  section {
    margin: 16px 0px;
    div {
      margin-bottom: 16px;
      max-width: 100%;
    }
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  padding-right: 26px;
  background: #5A5A5A;
  :first-child {
    width: 104px;
    height: 100%;
    background: rgba(#C4C4C4, 0.4);
  }
}

.hamburger {
  position: relative;
  width: 32px;
  height: 4px;
  &,
  &::after,
  &::before {
    background: rgba(#C4C4C4, 0.4);
  }
  &::after,
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &::before {
    bottom: 9px;
  }
  &::after {
    top: 9px;
  }
}

.Mobile .page {
  position: relative;
  width: 100%;
  padding: 0 12px 12px;
  margin: 0 auto;
}

section {
  .headline {
    width: 40%;
    height: 14px;
    background: rgba(#C4C4C4, 0.4);
  }
  .copy {
    height: 32px;
    background: rgba(#4D4D4D, 0.4);
    &:first-child {
      width: 86%;
    }
    &:last-child {
      width: 65%;
    }
  }
}

.Mobile .sectionImage {
  margin-top: 0;
  margin-bottom: 16px;
  padding: 0;
  .image {
    width: calc(100% + 24px);
    max-width: none;
    height: 170px;
    margin: 0 -12px 16px;
    background: rgba(#A0A0A0, 0.4);
  }
}

.Mobile .banner-wrapper {
  position: relative;
  margin: 32px -12px;
  width: calc(100% + 24px);
  max-width: none;
  text-align: center;
}

.banner-wrapper {
  position: relative;
}

.banner {
  width: 320px;
  height: 100px;
  margin: 0 auto;
  background-color: #C4C4C4;
  background-image: url('../../../assets/animationPlaceholder/320x100-F1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.Mobile .footer {
  width: calc(100% + 24px);
  max-width: none;
  height: 135px;
  margin: 0 -12px;
  background: rgba(#A0A0A0, 0.4);
}

.hit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}