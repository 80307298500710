// Breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1300px;

// Colors
$color-white: #fff;
$color-black: #000;
$color-blue: #009FDB;
$color-blue-light: #7DEADC;
$color-blue-dark: #002CC3;
$font-family: sans-serif;
$heading-family: sans-serif;
$brand-primary: #ce262f;

$body-font-family: 'Roboto Condensed', sans-serif;

$headline-font-family: '14FourPro';