$headerHeight: 58px;
$topPadding: 50px;


.Desktop {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1420px;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  
  iframe {
    display: block;
  }
  section {
    margin: 40px 0px;
    div {
      margin-bottom: 16px;
      max-width: 100%;
    }
  }
}

.sideMenu {
  flex: 0 0 230px;
  max-width: 230px;
  height: 100%;
  min-height: 100vh;

  @media (max-width: 1340px) {
    display: none;
  }

  div {
    margin-bottom: 27px;
    margin: 16px;
    background: #A8A8A8;
    opacity: 0.4;
    max-width: 100%;
    &:nth-child(1) {
      height: $headerHeight;
      width: 100%;
      margin: 0 0 $topPadding;
      background: #C4C4C4;
    }
    &:nth-child(2) {
      height: 27px;
      width: 146px;
    }
    &:nth-child(3) {
      height: 16px;
      width: 111px;
    }
    &:nth-child(4) {
      height: 16px;
      width: 124px;
    }
    &:nth-child(5) {
      height: 16px;
      width: 152px;
    }
    &:nth-child(6) {
      height: 16px;
      width: 76px;
    }
    &:nth-child(7) {
      height: 16px;
      width: 111px;
    }
  }
}

.Desktop .inner {
  position: relative;
  width: 100%;
  max-width: calc(100% - 230px);
  height: 100%;
  min-height: 100vh;

  @media (max-width: 1340px) {
    max-width: none;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: $headerHeight;
  width: 100%;
  margin-bottom: $topPadding;
  background: #5A5A5A;
  div {
    background: #C4C4C4;
    opacity: 0.37;
    width: 93px;
    height: 14px;
    margin-right: 24px;
    max-width: 100%;
    &:last-child {
      border-radius: 50%;
      width: 34px;
      height: 34px;
      margin-left: 40px;
    }
  }
}

.page {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-grow: 1;

  aside {
    min-width: 360px;
    flex: 0 0 360px;
    padding: 50px 16px 16px;
  }
}

.main-content {
  position: relative;
  padding: 16px;
  overflow: auto;
}

.bannerTop {
  width: 728px;
  height: 90px;
  background-color: #C4C4C4;
  background-image: url('../../../assets/animationPlaceholder/728x90-F1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.bannerSide {
  width: 300px;
  height: 600px;
  background-color: #C4C4C4;
  background-image: url('../../../assets/animationPlaceholder/300x600-F1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

section {
  .headline {
    width: 150px;
    height: 14px;
    background: #C4C4C4;
    opacity: 0.4;
  }
  .copy {
    width: 555px;
    height: 50px;
    background: rgba(#4D4D4D, 0.4);
  }
  .image {
    margin-bottom: 8px;
    height: 311px;
    width: 720px;
    background: rgba(#4D4D4D, 0.4);
  }
  .caption {
    height: 9px;
    width: 497px;
    background: rgba(#C4C4C4, 0.4);
  }
}

.tooltip-side,
.tooltip-top {
  position: absolute;
  background: #777777;
  border-radius: 5px;
  padding: 25px;
  z-index: 3;
  font-family: 'Poppins', sans-serif;
  p {
    text-align: left;
    color: white;
    font-size: 16px;
    line-height: 1.4;
  }
  &::after {
    content: ' ';
    position: absolute;
  }
}
.tooltip-top {
  width: 330px;
  right: 42px;
  transform: translateY(16px);
  &::after {
    bottom: 100%;
    right: 42px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 16px solid #777777;
  }
}
.tooltip-side {
  width: 330px;
  top: 12px;
  right: 100%;
  transform: translateX(-16px);
  &::after {
    top: 42px;
    left: 100%;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 16px solid #777777;
  }
}

.Desktop .footer {
  width: 100%;
  max-width: none;
  height: 185px;
  background: rgba(#A0A0A0, 0.4);
  @media (min-width: 1341px) {
    width: calc(100% + 230px);
    margin-left: -230px;
  }
}

.banner-wrapper {
  position: relative;
}

.hit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
}