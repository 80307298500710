.App {
  text-align: center;
}

html, body {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#ar-canvas {
  width: 100%;
  height: 100%;
  touch-action: none;  
}