.Home,
.HomeShowModal {
  position: relative;
  min-width: 100%;
  min-height: 100%;
}

// .HomeShowModal {
//   position: relative;
//   min-width: 100%;
//   height: 100vh;
//   overflow: hidden;
// }