.CopySection {
  position: relative;
  width: 100%;
  
  div {
    margin-bottom: 16px;
    height: 12px;
    background: rgba(#A0A0A0, 0.4);
  
    &:nth-child(1) {
      width: 650px;
      max-width: 100%;
    }
    &:nth-child(2) {
      width: 616px;
      max-width: calc(100% - 32px);
    }
    &:nth-child(3) {
      width: 623px;
      max-width: calc(100% - 27px);
    }
    &:nth-child(4) {
      width: 623px;
      max-width: calc(100% - 27px);
    }
    &:nth-child(5) {
      width: 639px;
      max-width: calc(100% - 11px);
    }
    &:nth-child(6) {
      width: 616px;
      max-width: calc(100% - 34px);
    }
    &:nth-child(7) {
      width: 639px;
      max-width: calc(100% - 11px);
    }
    &:nth-child(8) {
      width: 490px;
      max-width: 70%;
    }
  }
}
