// Font Mixins
@mixin font-att-aleck-slab-medium {
  font-family: 'ATT Aleck Slab Medium', sans-serif;
  font-weight: normal;
}
@mixin font-att-aleck-slab-medium-italic {
  font-family: 'ATT Aleck Slab Medium Italic', sans-serif;
  font-weight: normal;
}
@mixin font-att-aleck-slab-bold {
  font-family: 'ATT Aleck Slab Bold', sans-serif;
  font-weight: normal;
}
@mixin font-att-aleck-slab-black-italic {
  font-family: 'ATT Aleck Slab Black Italic', sans-serif;
  font-weight: normal;
}
@mixin font-att-aleck-sans-regular {
  font-family: 'ATT Aleck Sans Regular', sans-serif;
  font-weight: normal;
}
@mixin font-att-aleck-sans-bold {
  font-family: 'ATT Aleck Sans Bold', sans-serif;
  font-weight: normal;
}
@mixin font-att-aleck-sans-condensed-regular {
  font-family: 'ATT Aleck Sans Condensed Regular', sans-serif;
  font-weight: normal;
}