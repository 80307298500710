@import "breakpoint-sass";
@import "./variables";
@import "./mixins";

.invisible {
  display: none;
}
.interactions{
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  height: 80%;
  opacity: .2;
  z-index: 3;
  background-color: red;
}

