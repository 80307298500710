@import 'styles/common';

@mixin headers($font-size) {
  // font-family: '14FourPro';
  font-family: $headline-font-family;
  font-size: $font-size;
  font-weight: 900;
  text-transform: uppercase;
}

@mixin imgs($has-height) {
  background-size: cover;
  background-position: center;
  @if($has-height){
    height: 157.333vw;
  }
}

.Contact {

  h2 {
    @include headers(27vw);
  }
  h3 {
    @include headers(18vw);
    line-height: 14vw;
    letter-spacing: 0.02em;
  }
  h4 {
    @include headers(7.2vw);
  }
  h5{
    @include headers(5.6vw);
    font-weight: 600;
  }
  .red {
    color: #e00707;
    font-weight: 600;
  }
  .white {
    color: #fff;
    text-shadow: 0.8vw 0.8vw 3.2vw rgba(121,144,177,0.5);
    line-height: 18vw;
  }
  .btn {
    font-family: $headline-font-family;
    background: #EA0000;
    color: #fff;
    border: none;
    font-size: 5.6vw;
    font-weight: 600;
    padding: 1.5vw 3vw;
    border-radius: .5vw;
    width: 100%;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    margin: .3rem 0;
  }
  .journey {
    background-image: url('../../assets/8th-wall/the-plunge.png');
    @include imgs(true);

    .mountainLogo {
      margin: 5.333vw;
      height: 24vw;
    }
    .arrow {
      height: 16vw;
      margin-top: 9vw;
    }
  }
  .skiTips {
    position: absolute;
    left: 0%;
    width: 50%;
    top: 100vw;
  }
  .clouds {
    position: absolute;
    left: -18%;
    width: 150%;
    top: 118vw;
  }
  .guides {
    background-image: url('../../assets/8th-wall/contact-guides.png');
    @include imgs(true);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding-top: 24vw;
    padding-bottom: 5vw;
    
    .contacts {
      display: flex;
      justify-content: space-around;
      color: #fff;

      div {
        width: 16vw;
      }
      p {
        font-family: $headline-font-family;
        font-weight: 600;
        font-size: 3vw;
        letter-spacing: .2rem;
      }
      .hospitality {
        position: absolute;
        right: 3vw;
      }
    }
  }
  .topBoxes {
    background: #e8e8e8;
    height: 18.133vw;

    img {
      height: 36.267vw;
    }
  }
  .placesSection {
    margin: 21vw 0;

    .places {
      display: flex;
      flex-direction: column;

      @mixin background {
        margin: 2.667vw;
        margin-bottom: 0;
        padding: 13.333vw;
        @include imgs(false);
      }
      .america {
        background-image: url('../../assets/8th-wall/america.png');
        @include background;

        .btn {
          width: fit-content;
        }
      }
      .europe {
        background-image: url('../../assets/8th-wall/europe.png');
        @include background;
        
        .btn {
          width: fit-content;
        }
      }
      .asia {
        background-image: url('../../assets/8th-wall/asia.png');
        @include background;
        
        .btn {
          width: fit-content;
        }
      }
    }
  }
  .bottomBoxes {
    background: #e8e8e8;
    height: 9vw;

    img {
      height: 20vw;
      position: absolute;
      left: 41%;
      top: 502vw;
      z-index: -1;
    }
  }
  .footer {
    background-image: url('../../assets/8th-wall/footer.png');
    background-size: cover;
    background-position: center;
    height: 74.667vw;
    color: #fff;
    text-shadow: 0.8vw 0.8vw 3.2vw rgba(121,144,177,0.5);
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
}

