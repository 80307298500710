@import 'styles/common';

@keyframes sway {
  0% {
    transform: scale(1.3)
  }
  // 33% {
  //   transform: translateX(-0.25rem);
  // }
  50% {
    opacity: 0.25;
    transform: scale(1)
  }
  // 66% {
  //   transform: translateX(0.25rem);
  // }
  100% {
    transform: scale(1.3)
  }
}

.Experience {

  .loadingScreen {
    background: linear-gradient(#61b3ec, #add3f0);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3.5rem;
    color: $color-white;

    h4 {
      font-family: '14FourPro';
      font-weight: 500;
      font-size: 28px;
      text-transform: uppercase;
      margin: 0;
    }

    h3 {
      font-family: $headline-font-family;
      font-weight: 600;
      font-size: 36px;
      text-transform: uppercase;
      margin: 0.5rem 0 0;
    }

    p {
      font-family: $body-font-family;
      font-size: 18px;
      color: $color-black;
    }

  }
  .dots {
    display: flex;
    flex-direction: column;
    span {
      background-color: $color-white;
      border-radius: 50%;
      width: 4px;
      height: 4px;
      display: block;
      margin: 3px;
      animation: sway 1.5s ease-in-out infinite;

      &:nth-child(1) {
        animation-delay: 0.75s;
      }
      &:nth-child(2) {
        animation-delay: 0.625s;
      }
      &:nth-child(3) {
         animation-delay: 0.5s;
      }
      &:nth-child(4) {
        animation-delay: 0.375s;
      }
      &:nth-child(5) {
        animation-delay: 0.25s;
      }
      &:nth-child(6) {
        
        animation-delay: 0.125s;
      }
      &:nth-child(7) {
        
        animation-delay: 0;
      }
    }
  }

  .arSetupInstructions {
    padding: 1.1875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    background-color: rgba($color-blue, 0.5);

    h5 {
      color: white;
      font-family: '14FourPro';
      font-weight: 500;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .buttonWrapper {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;

  }

  .arActionButton {
    font-size: 28px;
    background-color: $brand-primary;
    padding: 1rem 2rem 0.75rem;
    border-radius: 12px;
    border: none;
    color: white;
    font-family: '14FourPro';
    font-weight: 500;
    text-transform: uppercase;
  }

  :global {
    .buttonSecondary {
      background-color: $color-black;
      font-size: 20px;
      padding: 0.5rem 1.5rem 0.375rem;
      margin-right: 0.9375rem;

      &_wrapper {
        top: 0.9375rem;
        justify-content: flex-end;
        bottom: auto;
      }
    }
  }
  .mountainLogo {
    margin: 1.5rem;
  }

  .eighthWallLogo {
    position: absolute;
    height: 2rem;
    bottom: 1rem;
  }
}


