.AsideSummary {
  position: relative;
  display: flex;
  align-items: flex-start;
  
}

.copy-wrapper {
  margin-bottom: 32px;
  background: none;
  div {
    margin-bottom: 12px;
    background: rgba(#A0A0A0, 0.4);
  }
}

.headline {
  width: 84px;
  height: 3px;
}

.copy-long {
  width: 206px;
  height: 21px;
}

.copy-short {
  width: 128px;
  height: 21px;
}

.image {
  margin-left: 16px;
  width: 95px;
  height: 88px;
  background: rgba(#A0A0A0, 0.4);
}
