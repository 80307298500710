.Modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 1.25vw;
  z-index: 1000;
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;

  @media (min-width: 1600px) {
    font-size: 16px;
  }

  &.active {
    opacity: 1;
    pointer-events: all;

    h3, p {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.5s ease-in-out;
    }

    p {
      transition-delay: 0.1s;
    }
  }
}

.innerWrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-height: 53vw;
  background: rgba(#000, .75);
  
  @media (min-width: 1600px) {
    min-height: 848px;
  }
}

.inner {
  max-width: 62%;
  @media (min-width: 1600px) {
    max-width: 992px;
  }
}

.text {
  color: #fff;
  
  h3,
  p {
    position: relative;
    transform: translateY(5vw);
    transition-delay: 0.5s;
    opacity: 0;
  }

  h3 {
    font-size: 4.5em;
    line-height: 1.4;
    font-weight: bold;
    text-transform: uppercase;
  }
  p {
    font-size: 2.125em;
    line-height: 1.4;
    font-weight: 500;
    max-width: 79%;
    margin: 0 auto;
    @media (min-width: 1600px) {
      max-width: 65%;
    }
  }
}

.Modal button {
  position: absolute;
  top: 7.5vw;
  right: 7.5vw;
  background: none;
  border: none;
  cursor: pointer;
  transition: opacity .2s ease-in-out;

  &:focus,
  &:hover {
    opacity: 0.7;
  }

  @media (min-width: 1600px) {
    top: 120px;
    right: 120px;
  }

  img {
    width: 3.6vw;
    height: 3.6vw;
    max-width: 50px;
    max-height: 50px;
    min-width: 25px;
    min-height: 25px;
  }
}