// #loadingContainer, #secondaryLoading {
//   font-family: unset;
//   h1 {
//     margin-bottom: .5rem;
//     line-height: 1;
//   }
//   p {
//     font-size: 1.2rem;
//   }
//   & > div {
//     // background: linear-gradient(to bottom right, #0064D2, #0037C0);
//     background: url('../assets/img/gradient-background.png');
//     background-size: cover;
//     background-position: center;
//   }
// }

// #secondaryLoading, #runtimeErrorContainer {
//   background: url('../assets/img/gradient-background.png');
//   background-size: cover;
//   background-position: center;
// }

// #loadBackground {
//   background: initial !important;
// }

// //header ui
// #requestingCameraPermissions {
//   color: white;
//   background-color: transparent;
// }

// //change camera icon color
// #requestingCameraIcon {
//   // filter: invert(1);
// }

// //loading text and image
// #loadImage {
//   width: 50%;
//   height: auto;
//   margin-left: 0;
// }

// .loadContainer {
//   position: absolute;
//   padding: 0;
//   width: 100%;
//   top: 45%;
//   left: 50%;
//   text-align: center;
//   transform: translate(-50%, -50%);
//   color: white;
//   font-weight: bold;
//   font-size: 16px;
//   background: transparent !important;
//   h2{
//     font-size: 2rem;
//     line-height: 1.2;
//   }
//   img{
//     width: 160px;
//     display: block;
//     margin: 0 auto 20px;
//   }
// }

// // prompt box
// .prompt-box-8w {
//   font-family: unset;
//   background-color: #AFAFAF !important;

//   p {
//     color: white;
//     font-size: 1.4rem;
//   }
// }
// .prompt-button-8w {
//   background-color: #9F9F9F !important;
// }

// .button-primary-8w {
//   background-color: #535353 !important;
// }


// .permission-error {
//   background: linear-gradient(to bottom right, #0064D2, #0037C0);
// }

// .foreground-image {
//   width: auto;
//   margin: 0 auto;
// }
// .bottom-message {
//   width: 100%;
//   text-align: center;
// }
// #loadingContainer {
//   position: fixed;
// }
// #cameraPermissionsErrorApple {
//   p {
//     line-height: 1.2;
//   }
// }
// #cameraPermissionsErrorAndroid {
//   padding: 40px;
//   overflow: auto;
//   .loading-error-instructions {
//     width: 100%;
//     font-size: 16px;
//     & > li {
//       margin-bottom: 16px;
//       &::before {
//         top: 0;
//         left: -40px;
//         width: 28px;
//         height: 28px;
//         border-radius: 50%;
//         font-size: 16px;
//         line-height: 1.75;
//       }
//       .foreground-image {
//         display: inline-block;
//       }
//     }
//   }
// }
// #runtimeErrorContainer {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding-bottom: 5vh;
// }
// .xr-error-icon {
//   max-width: 100px;
//   margin: 0 auto;
// }
// #error_text_header_top,
// #error_text_header_bottom,
// #error_text_header_unknown {
//   h2 {
//     font-size: 20px;
//     line-height: 1.2;
//   }
// }

// #recorder{
//   &:after{
//     position: absolute;
//     content: '';
//     width: 36px;
//     height: 36px;
//     background: url('../assets/svg/record.svg');
//     top: 50%;
//     left: 50%;
//     margin-left: -18px;
//     margin-top: -18px;
//     pointer-events: none;
//   }
// }


#loadingContainer {
  top: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  position: absolute;
  // background-color: $color-robins-egg;
  z-index: 2001;
}

#requestCameraPermissions {
 position: absolute;
 display: none;
}

#loadBackground {
  background-color: transparent;
  display: none;
}

#loadImageContainer {
  display: none;
}

#requestingCameraPermissions {
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  // background-color: rgba($color-robins-egg, 0.9);
  padding: 1.1875rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: $color-black

  img {
    height: 1.875rem;
    width: auto;
    display: block;
  }

  &:hover, &:focus {
    outline: none;

    // background-color: $color-robins-egg;
  }
}

#requestingCameraPermissions, #cameraSelectionWorldTrackingError, #cameraPermissionsErrorApple, #cameraPermissionsErrorAndroid, #deviceMotionErrorApple {
  background: linear-gradient(#61b3ec, #add3f0);
}

.absolute-fill, #requestingCameraPermissions, #cameraSelectionWorldTrackingError, #cameraPermissionsErrorApple, #cameraPermissionsErrorAndroid, #deviceMotionErrorApple {
  margin: 0;
  background-color: transparent;
  font-size: 0.875rem;
  color: $color-black;
  justify-content: center;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  min-width: 100%;
  height: 100%;

  p, div, ol, li, .loading-error-footer {
    font-size: 0.875rem;
    color: $color-black;
    margin: 0.375rem auto;
    line-height: normal;
    max-width: 14rem;
  }

  .loading-error-footer {
    margin-top: 1rem;
  }

  ol {
    list-style: decimal;
    padding-left: 1rem;
    margin: 0;
  }

  li {
    text-align: left;

    img {
      max-height: 1.75rem;
    }

    &:before {
      display: none;
    }
  }

  .highlight {
    font-weight: 600;
    font-size: 0.875rem;
    font-family: $body-font-family;
    color: $color-black;
  }

  .error-text-header, h1 {
    font-size: 1.5625rem;
    font-weight: 500;
    font-family: $body-font-family;
  }
}

#requestingCameraPermissions, #cameraSelectionWorldTrackingError, #cameraPermissionsErrorApple, #cameraPermissionsErrorAndroid, #deviceMotionErrorApple, .ar-view_loading-overlay, #userPromptError, #motionPermissionsErrorApple {
  margin-top: 0;

  &::before {
    content: "";
    background-size: auto 2rem;
    background-position: center 2rem;
    width: 100%;
    height: 6rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 400;
    background-repeat: no-repeat;
  }

  &::after {
    content: "";
    // background-color: $color-robins-egg;
    height: 5rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-size: auto 1.3125rem;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#cameraPermissionsErrorAndroid {
  div, ol {
    width: 12rem;
    text-align: left;
  }
}

.permission-error {
  h1 {
    font-size: 1.5625rem;
    margin: 0;
    margin: 0.5rem 0;
  }

  p {
    margin: 0.5rem 0;
  }
}

#cameraSelectionWorldTrackingError {
  img {
    display: none;
  }
}

#userPromptError {
  .main-button {
    width: 100%;
  }
}

#deviceMotionErrorApple {
  div, p, ol {
    width: 14rem;
    text-align: left;
    max-width: 14rem;
  }
}

#cameraPermissionsErrorAndroid, #microphonePermissionsErrorAndroid {
  justify-content: center;
}

#copyLinkViewAndroid, #linkOutViewAndroid { // These error screen is already handled at the beginning of the app and is completely broken anyway.
  display: none;
}

#microphonePermissionsErrorAppleMessage {
  color: $color-black;
}

img {
  &[src="//cdn.8thwall.com/web/img/loading/v2/reload.svg"] {
    background-size: 100%;
    background-position: center;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.75rem;
    background-repeat: no-repeat;
    background-image: url("../assets/8th-wall/reload.svg");
  }

  &[src="//cdn.8thwall.com/web/img/loading/v2/dots.svg"] {
    background-size: 100%;
    background-position: center;
    padding: 0.625rem;
    width: 1.25rem;
    height: 1.25rem;
    background-repeat: no-repeat;
    background-image: url("../assets/8th-wall/dots.svg");
  }

  &[src="//cdn.8thwall.com/web/img/loading/v2/camera.svg"] {
    background-size: 100%;
    background-position: center;
    width: 3.5rem;
    height: 3rem;
    padding: 1.5rem 1.75rem;
    background-repeat: no-repeat;
    margin-top: 0;
    margin-bottom: 1rem;
    background-image: url("../assets/8th-wall/camera.svg");
  }

  &[src="//cdn.8thwall.com/web/img/almostthere/v2/xtra-arrow.svg"] {
    display: none;
  }

  &[src="//cdn.8thwall.com/web/img/loading/v2/microphone.svg"] {
    background-size: auto 100%;
    background-position: center;
    width: 3.5rem;
    height: 3rem;
    padding: 1.5rem 1.75rem;
    background-repeat: no-repeat;
    margin-top: 0;
    margin-bottom: 1rem;
    background-image: url("../assets/8th-wall/microphone.svg");
  }
}

#cameraPermissionsErrorAppleReloadCamera {
  margin-top: 0;
  margin-bottom: 1rem;
}

#cameraPermissionsErrorAppleMessage {
  max-width: 14rem;
}

.camera-instruction-block, .camera-instruction-button {
  line-height: 1rem;
  border-radius: 1.25rem;
  padding: 0.625rem 0.75rem 0.375rem;
  margin: 0.125rem 0;
  font-size: 0.875rem;
}

body {
  div.prompt-box-8w {
    z-index: 2002;
    background-color: $color-black !important;
    filter: none;
    box-shadow: 0.125rem 0.125rem 0.5rem rgba($color-black, 0.35);
    padding: 1rem;
    border-radius: 0.75rem;
  
    p {
      color: $color-white;
      margin-bottom: 1.25rem;
    }
  
    button.prompt-button-8w {
      background-color: $color-white;
      color: $color-black;
      border-radius: 1.4125rem;
      font-family: $body-font-family;
      font-weight: 500;
      font-size: 1rem;
      padding: 0.75rem;
    }
  
    button.button-primary-8w {
      // background-color: $color-robins-egg;
      color: $color-black;
    }
  }
}

body .babylonUnmuteIcon, body .babylonUnmuteBtn {
  background-color: $color-black;
  padding: 1rem 2rem 0.75rem;
  border-radius: 12px;
  background-size: auto 77%;
  background-position-y: 7px;
  top: 0.9375rem !important;
  left: 0.9375rem !important;
  height: 37px;
  position: absolute;
  border: none;
  z-index: 4000;

  &.mute {
    background-image: url("../assets/8th-wall/speaker-mute.svg");
    background-repeat: no-repeat;
    background-position: center 7px;
  }
  &.unmute {
    background-image: url("../assets/8th-wall/speaker-unmute.svg");
    background-repeat: no-repeat;
    background-position: center 7px;
  }
}